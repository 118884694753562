import '../public/assets/css/style.css'
import React, {useEffect, useState} from "react";
import Preloader from '../components/elements/Preloader';
import "react-toastify/dist/ReactToastify.min.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {GlobalContextProvider} from "../providers/global.provider";
import {GoogleAnalytics} from "nextjs-google-analytics";
import {CookieConsent} from "react-cookie-consent";

function MyApp({Component, pageProps}) {
    const [loading, setLoading] = useState(true);
    const [allowCookies, setAllowCookies] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem("cookiesAccepted");
        if (cookiesAccepted === "true") {
            setAllowCookies(true);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const handleCookies = () => {
        localStorage.setItem("cookiesAccepted", "true");
        setAllowCookies(true);
    }

    return (
        <>
            {loading && <Preloader/>}
            <GlobalContextProvider>
                <Component {...pageProps} />
            </GlobalContextProvider>
            {allowCookies && <GoogleAnalytics trackPageViews strategy="lazyOnload"/>}
            <CookieConsent
                enableDeclineButton
                declineButtonText="Weiger cookies"
                buttonStyle={{
                    background: "#006D77",
                    color: "white",
                }} buttonText="Accepteer cookies" onAccept={handleCookies}>
                Deze website maakt gebruik van cookies om de gebruikerservaring te verbeteren.
            </CookieConsent>
        </>
    )
}

export default MyApp
