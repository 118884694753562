import {VacatureModel} from "../pages/api/vacature.model";
import React, {useContext, useEffect, useState} from "react";
import {useRouter} from "next/router";

export interface IGlobalContextProps {
    isLoading: boolean;
    vacatures: VacatureModel[];
    error: string | null;
    setIsLoading: (isLoading: boolean) => void;
    setVacatures: (vacatures: VacatureModel[]) => void;
    setError: (error: string) => void;
}

export const GlobalContext = React.createContext<IGlobalContextProps>({
    vacatures: [],
    isLoading: false,
    error: null,
    setIsLoading: () => {
    },
    setVacatures: () => {
    },
    setError: () => {
    }
});

const FETCH_PATHS = ["/", "/Vacatures"];

export const GlobalContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [vacatures, setVacatures] = useState([]);
    const [error, setError] = useState(null);
    const router = useRouter();

    useEffect(() => {
        if (FETCH_PATHS.includes(router.pathname) && vacatures.length === 0) {
            setIsLoading(true);
            fetchVacatures()
        }
    }, [router.pathname]);


    const fetchVacatures = () => {
        fetch("/api/vacatures")
            .then((response) => response.json())
            .then((data) => {
                setVacatures(data);
                setError(null);
            })
            .catch(() => {
                setError("Er is iets misgegaan, ververs aub");
            }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <GlobalContext.Provider
            value={{
                isLoading: isLoading,
                setIsLoading: setIsLoading,
                vacatures: vacatures,
                setVacatures: setVacatures,
                error: error,
                setError: setError
            } as IGlobalContextProps}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => useContext(GlobalContext);