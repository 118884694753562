import React from 'react';

const Preloader: React.FC = () => {
    return (
        <>
            <div id="preloader-active" >
                <div className="preloader d-flex align-items-center justify-content-center animate__animated animate__fadeOut animate__delay-1s">
                    <div className="preloader-inner position-relative">
                        <div className="text-center">
                            <div className="loader"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preloader;
